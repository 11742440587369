import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "./about.css";
import { HiOutlineCubeTransparent } from "react-icons/hi";

const About = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div className="section__padding">
      <div id="about" className="genz__about">
        <motion.p
          animate={mainControls}
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.25 }}
        >
          <HiOutlineCubeTransparent /> INTRODUCING BOLT
        </motion.p>
        <motion.h2
          animate={mainControls}
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.45 }}
        >
          <span>A creative studio that is </span>
          Specialized in Brand Strategy and Digital Creation.
          <span> Your brand, Our innovation touch.</span>
        </motion.h2>
      </div>
    </div>
  );
};

export default About;
