import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";

const root = document.getElementById("root");

const rootElement = ReactDOM.createRoot(root);
rootElement.render(<App />);
