import React from "react";
import "./footer.css";
import logo from "../../assets/logo.PNG";
// import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { TbMail } from "react-icons/tb";
import { CiLinkedin } from "react-icons/ci";

const Footer = () => {
  return (
    <div className="genz__footer_head">
      <div className="genz__footer">
        <div className="genz__footer-links-logo">
          <a href="#home">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="genz__footer-links-container web">
          <a>About Us</a>
          <a>Contact Us</a>
        </div>
        <div className="genz__footer-links-container web">
          <p>Our Expertise</p>
          <a>Digital Marketing</a>
          <a>Public Relations</a>
        </div>
        <div className="genz__footer-links-container web">
          <a>Web Design</a>
          <a>UI/UX</a>
          <a>Branding</a>
        </div>
        <div className="genz__footer-links-container mobile">
          <p>Our Expertise</p>
          <a>Digital Marketing</a>
          <a>Public Relations</a>
          <a>Web Design</a>
          <a>UI/UX</a>
          <a>Branding</a>
        </div>
      </div>
      <hr />
      <div className="genz__footer_1">
        <div className="genz__footer_1_links">
          <div>
            <CiLinkedin color="#ffffff" size="1.5rem" />
          </div>
          <div>
            <FaInstagram color="#ffffff" size="1.5rem" />
          </div>
          <div>
            <TbMail color="#ffffff" size="1.5rem" />
          </div>
        </div>
        <p>© Copyright. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
