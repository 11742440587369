import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "./cta.css";
import c1 from "../../assets/co1.png";
import { HiOutlineCubeTransparent } from "react-icons/hi";

const CTA = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div className="genz__cta section__padding" id="cta">
      <div className="genz__cta-content">
        <div className="genz__cta-content_text">
          <motion.p
            animate={mainControls}
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.25 }}
          >
            <HiOutlineCubeTransparent /> Customer story
          </motion.p>
          <motion.h2
            animate={mainControls}
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.45 }}
          >
            “Our growth no longer necessitates the recruitment and education of
            additional design professionals”
          </motion.h2>
        </div>
        <div className="genz__cta-content_image">
          <img src={c1} alt="a1" />
        </div>
      </div>
    </div>
  );
};

export default CTA;
