import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "./service.css";
import { HiOutlineCubeTransparent } from "react-icons/hi";
import { s1, s2, s3, s4, s5 } from "./index.js";

const Service = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div className="genz__service section__margin" id="service">
      <div className="genz__service-topic">
        <motion.p
          animate={mainControls}
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.25 }}
        >
          <HiOutlineCubeTransparent /> What you’ll get
        </motion.p>
        <motion.h2
          animate={mainControls}
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.45 }}
          className=" gradient__text1"
        >
          Strategy . Brand . Design
        </motion.h2>
      </div>
      <div className="genz__service-containers">
        {/* dm */}
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.55 }}
          className="genz__service-containers1_content"
        >
          <div className="genz__service-containers1_content__image">
            <img src={s1} alt="digital marketing" />
          </div>
          <div className="genz__service-containers_content__text">
            <h3>Digital Marketing</h3>
            <p>Boost brand reach, engage audiences and drive growth.</p>
          </div>
        </motion.div>
        {/* pr */}
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.65 }}
          className="genz__service-containers1_content"
        >
          <div className="genz__service-containers1_content__image">
            <img src={s3} alt="Public relation" />
          </div>
          <div className="genz__service-containers_content__text">
            <h3>Public Relations</h3>
            <p>Foster positive brand image and navigate the media landscape.</p>
          </div>
        </motion.div>
        {/* WD */}
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.75 }}
          className="genz__service-containers1_content"
        >
          <div className="genz__service-containers1_content__image">
            <img src={s4} alt="Web Development" />
          </div>
          <div className="genz__service-containers_content__text">
            <h3>Web Development </h3>
            <p>
              Crafting innovative websites tailored to enhance user experience.
            </p>
          </div>
        </motion.div>
      </div>
      <div className="genz__service-containers">
        {/* ui */}
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.85 }}
          className="genz__service-containers2_content"
        >
          <div className="genz__service-containers_content__text">
            <h3>UI/UX</h3>
            <p>User experience for impactful digital interactions.</p>
          </div>
          <div className="genz__service-containers2_content__image">
            <img src={s2} alt="Ui/Ux" />
          </div>
        </motion.div>
        {/* brand */}
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.95 }}
          className="genz__service-containers2_content"
        >
          <div className="genz__service-containers_content__text">
            <h3>Branding</h3>
            <p>Powering brands with strategic identity & design</p>
          </div>

          <div className="genz__service-containers2_content__image">
            <img src={s5} alt="Branding" />
          </div>
        </motion.div>
      </div>
      <div className="genz__service-container">
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 1.05 }}
          className="genz__service-container__text"
        >
          <p>
            <HiOutlineCubeTransparent color="#ddff00" /> SEO
          </p>
        </motion.div>
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 1.15 }}
          className="genz__service-container__text"
        >
          <p>
            <HiOutlineCubeTransparent color="#ddff00" /> Corporate Reputation
          </p>
        </motion.div>
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 1.25 }}
          className="genz__service-container__text"
        >
          <p>
            <HiOutlineCubeTransparent color="#ddff00" /> Mobile Apps
          </p>
        </motion.div>
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 1.35 }}
          className="genz__service-container__text"
        >
          <p>
            <HiOutlineCubeTransparent color="#ddff00" /> Social Media Marketing
          </p>
        </motion.div>
      </div>
      <div className="genz__service-container">
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 1.45 }}
          className="genz__service-container__text"
        >
          <p>
            <HiOutlineCubeTransparent color="#ddff00" /> Rollover hours
          </p>
        </motion.div>
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 1.55 }}
          className="genz__service-container__text"
        >
          <p>
            <HiOutlineCubeTransparent color="#ddff00" /> Premium designers
          </p>
        </motion.div>
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 1.65 }}
          className="genz__service-container__text"
        >
          <p>
            <HiOutlineCubeTransparent color="#ddff00" /> Multilingual support
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Service;
