import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "./work.css";
import { HiOutlineCubeTransparent } from "react-icons/hi";
import icon from "../../assets/icon.png";

const Work = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div className="genz__work section__margin" id="work">
      <div className="genz__service-topic">
        <motion.p
          animate={mainControls}
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.25 }}
        >
          <HiOutlineCubeTransparent /> How it works
        </motion.p>
        <motion.h2
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.45 }}
          className=" gradient__text1"
        >
          Top–notch designs, <br /> We create masterpieces.
        </motion.h2>
      </div>
      <div className="genz__service-containers">
        {/* dm */}
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.6 }}
          className="genz__work-containers1_content"
        >
          <div className="genz__work-containers1_content__image">
            <img src={icon} alt="icon" />
          </div>
          <div className="genz__work-containers_content__text">
            <h3>Tell us your vision</h3>
            <p>
              Choose a plan and share your project details with us: we’re here
              to listen.
            </p>
          </div>
        </motion.div>
        {/* pr */}
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.75 }}
          className="genz__work-containers1_content"
        >
          <div className="genz__work-containers1_content__image">
            <img src={icon} alt="icon" />
          </div>
          <div className="genz__work-containers_content__text">
            <h3>Receive the magic</h3>
            <p>
              Sit back and relax: our expert will turn your vision into reality.
            </p>
          </div>
        </motion.div>

        {/* WD */}
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.9 }}
          className="genz__work-containers1_content"
        >
          <div className="genz__work-containers1_content__image">
            <img src={icon} alt="icon" />
          </div>
          <div className="genz__work-containers_content__text">
            <h3>Get ongoing support</h3>
            <p>We ensure you have continuous access to our services.</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Work;
