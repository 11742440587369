import React, { useEffect, useRef, useState } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "./header.css";
import emailjs from "@emailjs/browser";
import l2 from "../../assets/l2.png";
import l3 from "../../assets/l3.png";
import l4 from "../../assets/l4.png";

const Header = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const [requestSuccess, setRequestSuccess] = useState(false); // State for success message

  const notify = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zogoa2p",
        "template_4oe7ke7",
        notify.current,
        "d_5p5l8-gzL3TSnt4"
      )
      .then(
        (result) => {
          console.log(result.text);
          setRequestSuccess(true); // Set state to true on successful submission
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div className="genz__header section__padding " id="home">
      <div className="genz__header-content">
        <div className="genz__header-content_center">
          <motion.h1
            animate={mainControls}
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75, z: 25 },
              visible: { opacity: 1, y: 0, z: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.25 }}
            className="gradient__text"
          >
            Elevating Your Brand Through Innovation
          </motion.h1>
          <motion.p
            animate={mainControls}
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.35 }}
          >
            Welcome to Gen-Z Innovates, the epicenter of digital innovation for
            the creative minds of this generation. Unleash your brand's
            potential with our diverse range of services. Explore digital
            excellence, redefine your story.
          </motion.p>
          <motion.form
            onSubmit={sendEmail}
            animate={mainControls}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.45 }}
            className="genz__header-content__input"
            ref={notify}
          >
            <input
              type="email"
              placeholder="name@email.com"
              name="from_email"
            />
            <button type="submit">Get notified</button>
            {requestSuccess && <p>Request Successfully placed!</p>}{" "}
            {/* Conditionally render success message */}
          </motion.form>
        </div>
      </div>
      <div className="genz__header-images">
        <div className="genz__header-image1">
          <motion.img
            animate={mainControls}
            variants={{
              hidden: { opacity: 0, x: -55, y: 55 },
              visible: { opacity: 1, x: 0, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.25 }}
            src={l2}
            alt="a2"
          />
        </div>
        <div className="genz__header-image2">
          <motion.img
            animate={mainControls}
            variants={{
              hidden: { opacity: 0, y: 55 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.25 }}
            src={l3}
            alt="a3"
          />
        </div>
        <div className="genz__header-image3">
          <motion.img
            animate={mainControls}
            variants={{
              hidden: { opacity: 0, x: 55, y: 55 },
              visible: { opacity: 1, x: 0, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.25 }}
            src={l4}
            alt="a4"
          />
        </div>
      </div>

      <div className="header">
        <div className="header-slide">
          <img src={l2} alt="slide" />
          <img src={l3} alt="slide" />
          <img src={l4} alt="slide" />
        </div>
        <div className="header-slide">
          <img src={l2} alt="slide" />
          <img src={l3} alt="slide" />
          <img src={l4} alt="slide" />
        </div>
        <div className="header-slide">
          <img src={l2} alt="slide" />
          <img src={l3} alt="slide" />
          <img src={l4} alt="slide" />
        </div>
      </div>
    </div>
  );
};

export default Header;
