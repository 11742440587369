import React, { useEffect, useRef } from "react";
import "./client.css";

import { c1, c2, c3, c4, c5, c6, c7, c8 } from "./imports";

const Client = () => {
  return (
    <div className="genz__client section__padding" id="client">
      <p>Our Client Community</p>

      <div class="logos">
        <div class="logos-slide">
          <img src={c1} />
          <img src={c2} />
          <img src={c3} />
          <img src={c4} />
          <img src={c5} />
          <img src={c6} />
          <img src={c7} />
          <img src={c8} />
        </div>
        <div class="logos-slide">
          <img src={c1} />
          <img src={c2} />
          <img src={c3} />
          <img src={c4} />
          <img src={c5} />
          <img src={c6} />
          <img src={c7} />
          <img src={c8} />
        </div>
      </div>
    </div>
  );
};

export default Client;
