import React, { useState, useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { HiOutlineCubeTransparent } from "react-icons/hi";
import emailjs from "@emailjs/browser";

import "./contacts.css";

const Contacts = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  // email
  const [isEmailSent, setIsEmailSent] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zogoa2p",
        "template_w0znfxt",
        form.current,
        "d_5p5l8-gzL3TSnt4"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsEmailSent(true);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="genz__contacts section__padding" id="contacts">
      <div className="genz__contacts-container">
        <div className="genz__contacts-intro">
          <motion.p
            animate={mainControls}
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.25 }}
          >
            <HiOutlineCubeTransparent /> WE WOULD LOVE TO HEAR FROM YOU
          </motion.p>
          <motion.h2
            animate={mainControls}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            transition={{ duration: 0.5, delay: 0.45 }}
          >
            Say Hello!
          </motion.h2>
        </div>
        <motion.div
          animate={mainControls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          transition={{ duration: 0.5, delay: 0.65 }}
          className="genz__contacts-form"
        >
          <p>Have a question? or just say hello!</p>
          <form ref={form} onSubmit={sendEmail}>
            <div className="genz__contacts-form_col">
              <div className="genz__contacts-form_div">
                <div className="single_form">
                  <label>Name:</label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="user_name"
                    required
                  ></input>
                </div>
              </div>
              <div className="genz__contacts-form_div">
                <div className="single_form">
                  <label>Phone:</label>
                  <input
                    type="tel"
                    autoComplete="off"
                    name="user_phone"
                  ></input>
                </div>
              </div>
            </div>
            <div className="genz__contacts-form_col">
              <div className="genz__contacts-form_div">
                <div className="single_form">
                  <label>Email:</label>
                  <input
                    type="email"
                    autoComplete="off"
                    name="user_email"
                  ></input>
                </div>
              </div>
              <div className="genz__contacts-form_div">
                <div className="single_form">
                  <label>Company:</label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="user_company"
                  ></input>
                </div>
              </div>
            </div>
            <div className="genz__contacts-form_div">
              <div className="single_form">
                <label>Message:</label>
                <textarea rows="6" autoComplete="off" name="message"></textarea>
              </div>
            </div>
            <div className="genz__contacts-form_button">
              <button type="submit">
                <span>send</span>
              </button>
              {isEmailSent && <p>Message sent successfully!</p>}
            </div>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default Contacts;
