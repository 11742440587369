import React, { useState, useEffect, useRef } from "react";
import { RiMenuLine , RiCloseLine } from "react-icons/ri";
import logo from "../../assets/logo.PNG";
import { motion, useInView, useAnimation } from "framer-motion";
import "./navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  // const [scrolled, setScrolled] = useState(false);

  // useEffect(() => {
  //   const onScroll = () => {
  //     if (window.scrollY > 50) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }
  //   };
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div>
      <motion.div
        animate={mainControls}
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: -25 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        transition={{ duration: 0.5, delay: 0.25 }}
        className="genz__navbar"
      >
        <div className="genz__navbar-links-logo">
          <a href="#home">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="genz__navbar-links">
          <div className="genz__navbar-links-container">
            <p>
              <a href="#home">Home</a>
            </p>
            <p>
              <a href="#about">About Us</a>
            </p>
            <p>
              <a href="#service">Services</a>
            </p>
            <p>
              <a href="#work">Work</a>
            </p>
          </div>
        </div>
        <div className="genz__navbar-sign">
          <button
            type="button"
            onClick={() => (window.location.href = "#contacts")}
          >
            Contact Us
          </button>
        </div>

        <div className="genz__navbar-menu">
          {toggleMenu ? (
            <RiCloseLine
              color="#fff"
              size={50}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenuLine 
              color="#fff"
              size={50}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div className="genz__navbar-menu_container scale-up-center">
              <div className="genz__navbar-menu_container-links">
                <p>
                  <a href="#home">Home</a>
                </p>
                <p>
                  <a href="#about">About Us</a>
                </p>
                <p>
                  <a href="#service">Services</a>
                </p>
                <p>
                  <a href="#work">Work</a>
                </p>
                <p>
                  <a href="#contacts">Contact US</a>
                </p>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default Navbar;
